<template>
  <div>
    <Header/>
    <Nav/>
    <div class="topImg">
      <div>المعلومات الأساسية للمعرض</div>
    </div>
    <div class="content">
      <div class="content-top"><span>لصفحة</span> <span>لصفحة الرئيسية > مرك</span><img class="iconhom" src="https://www.cas-expo.org.cn/web/images/home.png" alt=""></div>
      <div>
        <img src="@/assets/img/content-img.png" alt="">
        <div class="read-content">
          <!-- <img src="@/assets/img/itemNews.jpg" alt=""> -->
          <!-- <div class="read-title">معرض الصين والدول العربية</div>
          <div>إن معرض الصين والدول العربية معرض دولي شامل على المستوى الوطني، بموافقة مجلس الدولة الصيني وبالرعاية المشتركة لوزارة التجارة الصينية والمجلس الوطني الصيني لتنمية التجارة الدولية والحكومة الشعبية لمنطقة نينغشيا ذاتية الحكم لقومية هوي. منذ عام 2013، يلتزم المعرض بأهداف "توريث الصداقة وتعميق التعاون وتحقيق الكسب المشترك وتعزيز التنمية"، وقد تمت إقامة 5 دورات ناجحة في مدينة ينتشوان بمنطقة نينغشيا. وبعث الأمين العام شي جين بينغ رسالة التهنئة لجميع الدورات الخمس، وشارك فيها 112 دولة ومنطقة و24 قائدا من الصين والعالم و318 ضيف على درجة الوزير وأكثر من 6000 شركة صينية وأجنبية، ووقعت خلالها 1213 مشروع التعاون يغطي مجالات الزراعة الحديثة والتكنولوجيا المتقدمة والطاقة والصناعة الكيميائية والأدوية الحيوية وصناعة المعدات والبنية التحتية و"الإنترنت+ الرعاية الصحية" والسياحة وإلخ، مما يشكل جسر التواصل ويوفر بيئة مواتية للتعاون بين الشركات الصينية والعربية، ويدفع بقوة التعاون الاقتصادي والتجاري والاستثماري بين الصين والدول العربية وغيرها من الدول المطلة على "الحزام والطريق"، لقد أصبح المعرض منصة مهمة للتعاون الصيني العربي في بناء "الحزام والطريق".</div> -->
        </div>
      </div>
    </div>
    <div class="bottomImg">
      <img src="@/assets/img/logofont.png" alt="">
      <div>
        <span>:ICP {{'كل الحقوق محفوظة لدى الأمانة العامة لمعرض الصين والدول العربية'}} </span> <span class="number"> {{'38110050-7'}} </span> <span> {{'نينغشيا رقم تسجيل'}} </span>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Nav from '@/components/Nav'
export default {
  components: {
    Header,
    Nav
  }
}
</script>

<style lang="scss" scoped>
.topImg {
  background: url('~@/assets/img/itemBanner.png');
  min-height: 320px;
  background-size: cover;
  line-height: 320px;
  position: relative;
  div {
    text-align: center;
    font-size: 48px;
    color: #fff;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
.bottomImg {
  background: url('~@/assets/img/itemBottom.png');
  background-size: cover;
  min-height: 287px;
  text-align: center;
  color: rgba(255,255,255,0.65);
  font-family: 'GeezaPro';
  img {
    width: 384px;
    object-fit: cover;
    margin-top: 52px;
    margin-bottom: 57px;
  }
  .number {
    margin-right: 29px;
  }
}
.content {
  position: relative;
  min-height: 738px;
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
  img {
    width: 600px;
    object-fit: cover;
    // margin-left: 363px;
  }
  .read-title {
    font-size: 32px;
    color: #000;
    font-family: 'GeezaPro';
    text-align: right;
    margin-bottom: 17px;

  }
  .read-content {
    font-size: 16px;
    color: #555555;
    font-family: 'GeezaPro';
    width: 602px;
    height: 416px;
    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.1);
    border-radius: 3px;
    padding: 32px 24px 40px;
    position: absolute;
    top: 141px;
    background: #fff;
    right: 0;
    text-indent: 23px;
    line-height: 32px;
    text-align: right;
    background: url('~@/assets/img/itemNews.jpg');
    div {
      line-height: 32px;
    }
    direction: rtl;
    unicode-bidi:bidi-override;
    // left: 909px;
  }
}
.content-top {
  padding-bottom: 20px;
  text-align: right;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 48px;
  color: #333333;
  .iconhom {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
}
</style>